exports.components = {
  "component---src-templates-city-copper-jsx": () => import("./../../../src/templates/CityCopper.jsx" /* webpackChunkName: "component---src-templates-city-copper-jsx" */),
  "component---src-templates-city-fiber-jsx": () => import("./../../../src/templates/CityFiber.jsx" /* webpackChunkName: "component---src-templates-city-fiber-jsx" */),
  "component---src-templates-default-jsx": () => import("./../../../src/templates/Default.jsx" /* webpackChunkName: "component---src-templates-default-jsx" */),
  "component---src-templates-dynamic-zip-packages-jsx": () => import("./../../../src/templates/DynamicZipPackages.jsx" /* webpackChunkName: "component---src-templates-dynamic-zip-packages-jsx" */),
  "component---src-templates-one-trust-privacy-policy-jsx": () => import("./../../../src/templates/OneTrustPrivacyPolicy.jsx" /* webpackChunkName: "component---src-templates-one-trust-privacy-policy-jsx" */),
  "component---src-templates-state-copper-jsx": () => import("./../../../src/templates/StateCopper.jsx" /* webpackChunkName: "component---src-templates-state-copper-jsx" */),
  "component---src-templates-state-fiber-jsx": () => import("./../../../src/templates/StateFiber.jsx" /* webpackChunkName: "component---src-templates-state-fiber-jsx" */)
}

